<template>
  <div
    class="w-full md:flex md:items-stretch md:gap-20"
    :data-qa="'cart-product-' + item.listingId"
    data-test="cart-product"
  >
    <div class="flex grow items-start overflow-hidden">
      <RevLink
        v-if="props.item.image"
        :aria-label="item.title"
        target="_blank"
        :to="itemLink"
      >
        <RevIllustration
          :alt="item.title"
          class="h-60 w-60 max-w-60 cursor-pointer align-top md:h-[110px] md:w-[110px] md:max-w-[110px]"
          :height="60"
          :src="item.image"
          :width="60"
        />
      </RevLink>

      <div class="ml-12 inline-block md:ml-32">
        <span v-if="isOutOfStock" class="text-static-danger-hi body-2-bold">
          {{ i18n(translations.productOutOfStock) }}
        </span>
        <RevLink
          class="text-left"
          target="_blank"
          :to="itemLink"
          :underlined="false"
        >
          <div class="body-1-bold">{{ item.title }}</div>
        </RevLink>

        <p
          v-if="item.gradeName && shouldDisplayNoGrade"
          class="text-static-default-low body-2"
        >
          {{ i18n(translations.productGrade) }}
          {{
            getDescription(
              i18n,
              item.gradeId as 9 | 10 | 11 | 12 | null,
              item.specialOfferType as 0 | 7 | null,
            ).join(', ')
          }}
        </p>
        <div v-else-if="item.gradeName" class="flex">
          <p class="text-static-default-low body-2">
            {{ i18n(translations.productGrade) }}
          </p>
          <p class="text-static-default-low body-2 ml-4">
            {{ gradeName }}
          </p>
        </div>

        <Perks v-if="hasNewPerksDisplay" class="my-16" :item />

        <div v-else class="flex flex-col gap-8">
          <AccessoriesSummary
            :accessories="item.providedAccessories"
            class="text-static-default-low body-2"
          />

          <Warranty link-classnames="!text-static-default-low body-2-link" />

          <ShippingPromise
            v-if="!isOutOfStock"
            :choices="item.options[0].choices"
            class="text-static-default-low body-2-bold mt-8"
          />
        </div>

        <PriceBlock class="mt-16 md:hidden" :is-out-of-stock :item />
      </div>
    </div>

    <div class="flex flex-col md:shrink-0 md:justify-between">
      <PriceBlock
        class="mb-20 hidden text-right md:block"
        :is-out-of-stock
        :item
      />

      <div
        class="my-20 flex justify-between md:my-0 md:items-center md:justify-end"
      >
        <div
          class="flex items-center gap-8 md:flex-row-reverse md:items-center"
        >
          <ArticleQuantitySelector
            v-if="!isOutOfStock"
            :id="`quantity-${item.listingId}`"
            :disabled="item.mobilePlan !== null"
            :limit="10"
            :listing-title="item.title"
            :max="item.quantityMax"
            :model-value="quantity"
            @update:model-value="
              (quantity: number) => emit('update-quantity', quantity)
            "
          />
          <span
            v-if="!hasCartStockTagPrice && !isOutOfStock"
            class="text-static-success-hi body-2-bold md:text-right"
            :class="{
              'text-static-success-hi': inStock,
              'text-static-brand-mid': !inStock,
            }"
          >
            {{
              inStock
                ? i18n(translations.itemInStock)
                : i18n(translations.itemLowStock, { stock: item.quantityMax })
            }}
          </span>
        </div>
        <RevButton
          class="ml-12"
          data-qa="delete-item-button"
          size="small"
          variant="secondaryDestructive"
          @click="emit('remove')"
        >
          {{ i18n(translations.productRemove) }}
        </RevButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { CartItem } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { getDescription } from '~/scopes/product/components/NoGrade/utils/getDescription'

import translations from './Product.translations'
import AccessoriesSummary from './components/AccessoriesSummary/AccessoriesSummary.vue'
import Perks from './components/Perks/Perks.vue'
import PriceBlock from './components/PriceBlock/PriceBlock.vue'
import ArticleQuantitySelector from './components/QuantitySelector/ArticleQuantitySelector.vue'
import ShippingPromise from './components/ShippingPromise/ShippingPromise.vue'
import Warranty from './components/Warranty/Warranty.vue'

const props = defineProps<{
  item: CartItem
  quantity: number
  isOutOfStock: boolean
}>()

const emit = defineEmits<{
  (e: 'remove'): void
  (e: 'update-quantity', quantity: number): void
}>()

const experiments = useExperiments()
const i18n = useI18n()

const itemLink = computed(() => ({
  ...props.item.link,
  params: {
    locale: props.item.link?.params?.locale,
    slugV2: props.item.link?.params?.slugV2,
    uuid: props.item.link?.params?.uuid,
  },
}))

const gradeName = computed(() => {
  if (props.item.gradeExtended.hasNewBattery) {
    return i18n(translations.productGradeWithNewBattery, {
      grade: props.item.gradeName,
    })
  }

  return props.item.gradeName
})

const shouldDisplayNoGrade = computed(
  () =>
    experiments['experiment.ppNoGrade'] === 'withNoGrade' &&
    props.item.marketplaceCategoryId === 2,
)

const hasCartStockTagPrice = computed(
  () => experiments['experiment.cartStockTagPrice'] === 'cartStockWithPrice',
)

const hasNewPerksDisplay = computed(
  () =>
    experiments['experiment.cartItemCardPerks'] ===
      'newItemCardPerksBackground' ||
    experiments['experiment.cartItemCardPerks'] ===
      'newItemCardPerksNoBackground',
)

const inStock = computed(() => {
  return props.item.quantityMax > 10
})
</script>
