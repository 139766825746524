export default {
  youSaveDisplay: {
    id: 'product_page_you_save_amount_display',
    defaultMessage: 'Save {reduction}',
  },
  bundlingDiscount: {
    id: 'funnel_cart_bundle_discount',
    defaultMessage: 'Bundling saved you {price}',
  },
  itemInStock: {
    id: 'cart_in_stock',
    defaultMessage: 'In Stock',
  },
  itemLowStock: {
    id: 'cart_low_stock',
    defaultMessage: 'Only {stock} left',
  },
  itemLowStockAB: {
    id: 'cart_low_stock_at_this_price',
    defaultMessage: 'Only {stock} left at this price',
  },
}
