<template>
  <div>
    <div>
      <span
        v-if="hasCartStockTagPrice && !isOutOfStock"
        class="text-static-success-hi body-2-bold"
        :class="{
          'text-static-success-hi': inStock,
          'text-static-brand-mid': !inStock,
        }"
      >
        {{
          inStock
            ? i18n(translations.itemInStock)
            : i18n(translations.itemLowStockAB, { stock: item.quantityMax })
        }}
      </span>
    </div>
    <div v-if="!isOutOfStock" class="heading-3" data-qa="price">
      {{ i18n.price(item.price) }}
    </div>
    <div
      v-if="
        !isOutOfStock && shouldDisplayFormerPriceAndYouSave && item.formerPrice
      "
    >
      <div class="mt-4 flex flex-row items-baseline space-x-4 md:justify-end">
        <RevTag
          class="whitespace-nowrap"
          :label="
            i18n(translations.youSaveDisplay, {
              reduction: i18n.price(item.formerPrice - item.price),
            })
          "
          variant="success"
        />
        <div class="z-[2]">
          <StrikedProductPrice :price="item.formerPrice" />
        </div>
      </div>
    </div>

    <RevTag
      v-if="item.mobilePlan"
      class="mt-4"
      data-qa="mobile-plan-subsidy"
      :label="
        i18n(translations.bundlingDiscount, {
          price: i18n.price(item.mobilePlan.selectedOffer.subsidy),
        })
      "
      variant="primary"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import type { CartItem } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevTag } from '@ds/components/Tag'

import StrikedProductPrice from '../StrikedProductPrice/StrikedProductPrice.vue'

import translations from './PriceBlock.translations'

const props = defineProps<{
  item: CartItem
  isOutOfStock: boolean
}>()

const experiments = useExperiments()
const i18n = useI18n()

const {
  market: { countryCode },
} = useMarketplace()

const shouldDisplayFormerPriceAndYouSave = computed(
  () =>
    props.item.existsNew &&
    ![MarketCountryCode.JP].includes(countryCode) &&
    props.item.marketplaceCategoryId === 2,
)

const hasCartStockTagPrice = computed(
  () => experiments['experiment.cartStockTagPrice'] === 'cartStockWithPrice',
)

const inStock = computed(() => {
  return props.item.quantityMax > 10
})
</script>
